<template>
  <div class="home">
    <button class="reset" @click="reset()">X</button>
    <h2>HR_TO_KIRA_TAVOLLET xml készítő</h2>
    <form>
      <div class="form-row">
        <div class="formfield">
          <label for="atadasszam">Átadásszám</label>
          <input
            class="form-control"
            id="atadasszam"
            type="number"
            v-model="atadasszam"
            placeholder="Átadásszám"
          />
        </div>
        <div class="formfield">
          <label for="atadasdatum">Átadás dátuma</label>
          <input
            class="form-control"
            id="atadasdatum"
            type="date"
            v-model="atadasdatum"
            placeholder="Átadás dátum"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="formfield">
          <label for="file">Havi_szabadsagok.csv</label>
          <input
            class="form-control"
            id="file"
            type="file"
            accept=".csv"
            @change="handleFileUpload($event)"
          />
        </div>
      </div>
    </form>
    <div v-if="uploading">
      <h2>Feldolgozás...</h2>
    </div>
    <div class="button-container" v-if="!uploading && !parsed && file">
      <button class="btn btn-primary" @click="parseFile()">
        Csv feldolgozása
      </button>
    </div>
    <div class="button-container" v-if="parsed">
      <h2>Sikeres csv feldolgozás!</h2>
      <div class="csv-result-container">
        <ul>
          <li
            v-for="(header, key) in content.errors"
            v-bind:key="'header-' + key"
          >
            Hiba: {{ header.message }}. Sor: {{ header.row }}
          </li>
        </ul>

        <p>
          <span>Csv sorok száma: {{ csvRows }}.</span>
        </p>
        <p>
          <span
            >Hiányzó adóazonosító: {{ missingTaxNumber }} esetben (ezen nem
            kerülnek a generált xmlállományba).</span
          >
        </p>
      </div>
      <button class="btn btn-primary" @click="createXml()">
        Xml generálás
      </button>
    </div>
    <div class="button-container" v-if="parsed && generated">
      <h2>Sikeres xml generálás!</h2>
      <button class="btn btn-success" @click="download()">Letöltés</button>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import o2x from "object-to-xml";
import fileSaver from "file-saver";

export default {
  name: "Generator",
  components: {},
  data() {
    return {
      csvDelimiter: ",",
      xml: null,
      file: "",
      content: {},
      parsed: false,
      generated: false,
      uploading: false,
      atadasszam: 1,
      atadasdatum: new Date().toISOString().split("T")[0],
      jogcim: {
        R: 91001, //Rendes szab
        M: 91001, //Munkaképesség csökkenés
        G: 91001, //Gyermek után járó
        U: 93068, //Unoka születése
        L: 93069, //Első házasság
        H: 93016, //Hozzátartozó halála
        T: 91002, //Tanulmányi szab
        J: 91016, //Járványkezelés
        A: 91015, //Apákat megillető
        E: 91001, //Életkor alapján járó
        V: 93070, //Alkotói szab
      },
      juttip: {
        20: 6,
        6: 2,
        9: 6,
        18: 6,
      },
    };
  },
  computed: {
    csvRows: function () {
      if (Object.keys(this.content).length) {
        return this.content.data.length;
      }
      return 0;
    },
    missingTaxNumber: function () {
      if (Object.keys(this.content).length) {
        return this.content.data.filter((jv) => !jv.JV_SZEM_ADOAZON).length;
      }
      return 0;
    },
  },
  methods: {
    reset() {
      location.reload();
    },
    download() {
      const blob = new Blob([this.xml], { type: "text/plain;charset=utf-8" });
      fileSaver.saveAs(blob, "hr_to_kira_tavollet_" + this.atadasszam + ".xml");
    },
    createXml() {
      const xmlObj = {
        '?xml version="1.0" encoding="utf-8"?': null,
        HR_TO_KIRA_TAVOLLET: {
          ADMIN: {
            AD_TORZSSZAM: 789268,
            AD_VP_AZON: 44330,
            AD_ATSSZAM: this.atadasszam,
            AD_ATSDATUM: this.atadasdatum,
            AD_HRNEV: "Provizor",
            AD_HRVERZIO: "1.0",
          },
          JOGVISZONY: this.content.data
            .filter((jv) => jv.JV_SZEM_ADOAZON)
            .map((row) => {
              const mod = {
                ...row,
                JV_HRAZON: null,
                JV_JOGVKEZDETE: new Date(row.JV_JOGVKEZDETE)
                  .toISOString()
                  .split("T")[0],
                JV_JOGVVEGE:
                  row.JV_JOGVVEGE === ""
                    ? "3999-12-31"
                    : new Date(row.JV_JOGVVEGE).toISOString().split("T")[0],
                TAVOLLET: {
                  TVF_TKEZDETE: `${
                    new Date(row.DATUM).toISOString().split("T")[0]
                  }T00:00:00.000000`,
                  TVF_TVEGE: `${
                    new Date(row.DATUM).toISOString().split("T")[0]
                  }T00:00:00.000000`,
                  TVF_JOGCIM: this.jogcim[row.JELLEG],
                  TVF_JUTTIP:
                    row.JELLEG === "T"
                      ? 6
                      : this.juttip[parseInt(row.JV_JOGTIPKOD, 10)],
                  TVF_TAVSZAZ: null,
                  TVF_FIXOSSZEG: null,
                  TVF_HRAZON: row.JELLEG,
                },
              };
              delete mod.DATUM;
              delete mod.JELLEG;
              return mod;
            }),
        },
      };
      this.xml = o2x(xmlObj);
      this.generated = true;
    },
    handleFileUpload(event) {
      if (event) {
        this.file = event.target.files[0];
      } else {
        this.file = null;
      }
    },
    parseFile() {
      this.uploading = true;
      Papa.parse(this.file, {
        delimiter: this.csvDelimiter,
        encoding: "ISO-8859-2",
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results;
          this.parsed = true;
          this.uploading = false;
        }.bind(this),
      });
    },
  },
};
</script>

<style scoped>
.home {
  position: relative;
}
.reset {
  position: absolute;
  top: 0;
  right: 0;
}
.csv-result-container > ul {
  font-size: 0.8rem;
  font-family: "Courier New", Courier, monospace;
  color: red;
}
</style>
